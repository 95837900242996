<template>
<v-card>
  <v-sheet height="64">
    <v-toolbar
      flat
    >
      <!-- <v-btn
        outlined
        class="mr-4"
        color="grey darken-2"
        @click="setToday"
      >
        今
      </v-btn> -->
      <v-btn
        fab
        text
        small
        color="grey darken-2"
        @click="prev"
      >
        <v-icon small>
          chevron_left
        </v-icon>
      </v-btn>
      <v-btn
        fab
        text
        small
        color="grey darken-2"
        @click="next"
      >
        <v-icon small>
          chevron_right
        </v-icon>
      </v-btn>
      <v-toolbar-title v-if="$refs.calendar">
        {{ $refs.calendar.title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu
        bottom
        right
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            outlined
            color="grey darken-2"
            v-bind="attrs"
            v-on="on"
          >
            <span>{{ typeToLabel[type] }}</span>
            <v-icon right>
              arrow_drop_down
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="type = 'day'">
            <v-list-item-title>1天</v-list-item-title>
          </v-list-item>
          <v-list-item @click="type = 'week'">
            <v-list-item-title>1周</v-list-item-title>
          </v-list-item>
          <v-list-item @click="type = 'month'">
            <v-list-item-title>1月</v-list-item-title>
          </v-list-item>
          <v-list-item @click="type = '4day'">
            <v-list-item-title>4天</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
  </v-sheet>
  <v-sheet height="600">
    <v-calendar
      ref="calendar"
      v-model="focus"
      color="primary"
      :events="events"
      :event-color="getEventColor"
      :type="type"
      locale='zh-cn'
      @click:event="showEvent"
      @click:more="viewDay"
      @click:date="viewDay"
      @change="updateEvents"
    ></v-calendar>
    <v-menu
      v-model="selectedOpen"
      :close-on-content-click="false"
      :activator="selectedElement"
      offset-x
    >
      <v-card
        color="grey lighten-4"
        min-width="350px"
        flat
      >
        <v-toolbar
          :color="selectedEvent.color"
          dark
        >
          <!-- <v-btn icon>
            <v-icon>edit</v-icon>
          </v-btn> -->
          <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon :disabled="selectedEvent.statusCode != 0">
            <v-icon @click="deleteLesson(selectedEvent.lessonId)">delete</v-icon>
          </v-btn>
          <!-- <v-btn icon>
            <v-icon>more_vert</v-icon>
          </v-btn> -->
        </v-toolbar>
        <v-card-text>
          <div>项目: {{selectedEvent.program}}</div>
          <div>机构: {{selectedEvent.organization}}</div>
          <div v-if="selectedEvent.title">名称: {{selectedEvent.title}}</div>
          <div>教师: {{selectedEvent.tutor}}</div>
          <div>状态: {{selectedEvent.status}}</div>
          <div>班级: {{selectedEvent.class}}</div>
          <div>时间: {{selectedEvent.timeString}}</div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="secondary"
            @click="selectedOpen = false"
          >
            关闭
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </v-sheet>
</v-card>
</template>

<script>
import LessonService from '@/services/LessonService'
import { add, format } from 'date-fns'
import { LessonStatusLookup } from '../enums'

export default {
  props: { // make it possible to pass in parameter <panel title="课程模版">
    classIds: {
      type: Array,
      default: function () { return [] }
    },
    organizationIds: {
      type: Array,
      default: function () { return [] }
    },
    programIds: {
      type: Array,
      default: function () { return [] }
    },
    tutorIds: {
      type: Array,
      default: function () { return [] }
    },
    statusCodes: {
      type: Array,
      default: function () { return [] }
    },
    lessonIds: {
      type: Array,
      default: function () { return [] }
    }
  },
  components: {
  },
  data () {
    return {
      // calendar
      focus: '',
      type: 'month',
      typeToLabel: {
        month: '月视图',
        week: '周视图',
        day: '天视图',
        '4day': '4天视图'
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      calendarStart: {},
      calendarEnd: {}
    }
  },
  computed: {
  },
  async mounted () {
  },
  filters: {
  },
  methods: {
    async deleteLesson (lessonId) { // 删除课程
      await LessonService.deleteLesson(lessonId)
      const indexToDelete = this.events.findIndex((events) => events.lessonId === lessonId)
      this.events.splice(indexToDelete, 1)
      this.selectedOpen = false
      console.log(`lesson(id:${lessonId}) has been deleted!`)
      this.$emit('sessionDelete')
    },
    async updateEvents ({ start, end }) {
      this.calendarStart = start
      this.calendarEnd = end
      const sinceDateTime = start.date + ' 00:00:00'
      const untilDateTime = end.date + ' 23:59:59'
      let events = []
      if (this.classIds.length || this.organizationIds.length || this.programIds.length || this.tutorIds.length) {
        let res = await LessonService.getLessonSessions({
          sinceDateTime,
          untilDateTime,
          classIds: this.classIds.length ? this.classIds : null,
          organizationIds: this.organizationIds.length ? this.organizationIds : null,
          programIds: this.programIds.length ? this.programIds : null,
          tutorIds: this.tutorIds.length ? this.tutorIds : null,
          statusCodes: this.statusCodes.length ? this.statusCodes : null,
          lessonIds: this.lessonIds.length ? this.lessonIds : null
        })
        let sessions = res.data
        console.log(sessions)
        sessions.forEach((session) => {
          let color = 'blue'
          switch (session.status_code) {
            case LessonStatusLookup.SCHEDULED: // 已排课
              color = 'grey'
              break
            case LessonStatusLookup.INSTRUCTED: // 已授课
              color = 'orange'
              break
            case LessonStatusLookup.ACCOMPLISHED: // 已结课
              color = 'green'
              break
          }
          let startTime = new Date(session.time)
          let endTime = add(new Date(session.time), { minutes: session.module_length_min * session.module_quantity })
          events.push({
            name: `${session.class}(${session.program_title})`,
            start: startTime,
            end: endTime,
            timeString: `${format(new Date(startTime), 'yyyy-MM-dd HH:mm')} - ${format(new Date(endTime), 'HH:mm')}`,
            color: color,
            timed: true,
            lessonId: session.id,
            program: session.program_title,
            organization: session.organization,
            class: session.class,
            status: session.status,
            statusCode: session.status_code,
            tutor: session.tutor
          })
        })
      }
      console.log(events)
      this.events = events
    },
    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor (event) {
      return event.color
    },
    setToday () {
      this.focus = ''
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => { this.selectedOpen = true }))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
